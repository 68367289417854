import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import CIInsightsIcon from '../assets/images/icons/ci-insights.svg'
import WIPicon from '../assets/images/icons/working-agreements.svg'
import ReviewRateIcon from '../assets/images/product/blurb-icons/all-work.png'
import PRIcon from '../assets/images/product/blurb-icons/fast.svg'
import SizeIcon from '../assets/images/product/blurb-icons/gear.png'
import ClockIcon from '../assets/images/product/blurb-icons/lock.png'
import InvestmentDistributionIcon from '../assets/images/product/blurb-icons/slide-and-dice.png'
import TrendsIcon from '../assets/images/product/blurb-icons/trends.png'
import BugIcon from '../assets/images/product/blurb-icons/zoom.png'
import MetaImage from '../assets/images/meta/software-team-metrics.png'
import Layout from '../components/Layout'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import ToolsBlock from '../components/blocks/ToolsBlock'
import Blurb from '../components/system/Blurb'
import CTABlock from '../components/blocks/CTABlock'
import GlobalStyleReset from '../components/system/GlobalStyleReset'
import { responsiveScale } from '../styles/helpers'
import MosaicBlockStartup from '../components/blocks/MosaicBlock'
import Stack from '../components/primitives/Stack'
import Box from '../components/primitives/Box'
import IllustrationBlock from '../components/blocks/IllustrationBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/insights/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/startup-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    lead: file(relativePath: { eq: "images/product/surveys/lead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/pr-filters.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/issue-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    worklog: file(
      relativePath: { eq: "images/product/overview/anti-patterns.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    dora: file(relativePath: { eq: "images/blurbs/dora-670.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    space: file(
      relativePath: { eq: "images/product/overview/investment-balance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
  }
`

const SoftwareMetricsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Software team metrics"
      variant="dark"
      isNew
      description="Minimize friction, prioritize more effectively, and create a better product with reliable engineering metrics."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          title={<>Ship faster with actionable software team metrics</>}
          content="Minimize friction, prioritize more effectively, and create a better product with reliable engineering metrics."
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <Box
          backgroundColor="black"
          paddingY={40}
          paddingX={{ xs: 20, md: 36 }}
        >
          <DarkLogoBlock hideBackground />
        </Box>
        <Box>
          <LeadBlock
            heading={
              <>Spot improvement areas with comprehensive engineering data</>
            }
            content="Swarmia combines system metrics and developer feedback so you know exactly what to improve and how."
          />
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(32)}
            image={getImage(data.lead)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteBlock person="dave" />
        <LeadBlock
          heading="Out-of-the-box support for DORA & SPACE"
          content="When you’re just getting started on your developer productivity journey, DORA and SPACE are often great places to start. Swarmia makes tracking both a breeze."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(92)}
          columns={[
            <Blurb
              key="1"
              title="DORA metrics"
              text="Get immediate visibility into DORA metrics: deployment frequency, change lead time, change failure rate, and mean time to recovery."
              href="/dora-metrics/"
              target="_self"
              image={getImage(data.dora)}
              isLarge
            />,
            <Blurb
              key="2"
              title="SPACE framework"
              text="Get a holistic picture of developer productivity by measuring all five aspects of the SPACE framework."
              href="/space/"
              target="_self"
              image={getImage(data.space)}
              isLarge
            />,
          ]}
        />
        <QuoteBlock person="walterDora" />
        <CTABlock title="Get the first insights in minutes. Get&nbsp;started&nbsp;today." />
        <LeadBlock
          heading="Here are some of the metrics you can track with Swarmia"
          content="Track the metrics that improve code quality and accelerate delivery by identifying and resolving bottlenecks."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          columns={[
            <Blurb
              key="1"
              title="PR cycle time"
              text="Get pull requests through faster by eliminating common bottlenecks."
              icon={PRIcon}
            />,
            <Blurb
              key="2"
              title="Deployment frequency"
              text="Keep track of how often your teams are deploying code to production."
              icon={TrendsIcon}
            />,
            <Blurb
              key="3"
              title="Investment distribution"
              text="Understand where engineering time goes and make better prioritization decisions."
              icon={InvestmentDistributionIcon}
            />,
          ]}
        />
        <ColumnsBlock
          paddingBottom={responsiveScale(32)}
          columns={[
            <Blurb
              key="1"
              title="Bug insights"
              text="Stay on top of the bugs your team is working on and the time it takes to fix them."
              icon={BugIcon}
            />,
            <Blurb
              key="2"
              title="Batch size"
              text="Roll out features to your customers faster and with less risk."
              icon={SizeIcon}
            />,
            <Blurb
              key="3"
              title="CI insights"
              text="Troubleshoot pipeline issues to reduce CI wait times and costs."
              icon={CIInsightsIcon}
            />,
          ]}
        />
        <ColumnsBlock
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Review time"
              text="Identify review bottlenecks and get your work merged faster."
              icon={ClockIcon}
            />,
            <Blurb
              key="2"
              title="Review rate"
              text="Maintain high code quality by maximizing the percentage of reviewed code."
              icon={ReviewRateIcon}
            />,
            <Blurb
              key="3"
              title="WIP limits"
              text="Allow teams to set their own work-in-progress limits to gain focus."
              icon={WIPicon}
            />,
          ]}
        />
        <QuoteBlock person="alex" />
        <LeadBlock
          heading="Go beyond metrics"
          content="In addition to metrics, Swarmia helps you build the feedback loops you need to get a little bit better every day."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Adopt working agreements"
              text="Give your team the feedback loops they need to get a little bit better every day."
              image={getImage(data.workingAgreement1)}
            />,
            <Blurb
              key="2"
              title="Two-way Slack notifications"
              text="Read, reply, and react to GitHub comments without leaving Slack."
              image={getImage(data.workingAgreement2)}
            />,
            <Blurb
              key="3"
              title="Work log"
              text="Get a full picture of work in progress and regain focus on what matters."
              image={getImage(data.worklog)}
            />,
          ]}
        />
        <ToolsBlock />
        <CTABlock title="Ready to start shipping faster?" />
        <MosaicBlockStartup
          title="Get 50% off for 12 months"
          content={
            <Stack color="black">
              <p>
                Startups eligible for the Swarmia startup program save 50% on
                their subscription for the first 12 months.
              </p>
              <br />
              <p>
                <a href="/startups">
                  Read more about Swarmia startup program →
                </a>
              </p>
              <p>
                <br />
                Companies with fewer than 10 developers can use Swarmia for
                free.
              </p>
              <br />
              <p>
                <a href="https://app.swarmia.com/signup/">
                  Sign up for a free account →
                </a>
              </p>
            </Stack>
          }
          imageAlign="right"
          image={getImage(data.mosaicRight)!}
        />
      </GlobalStyleReset>
    </Layout>
  )
}

export default SoftwareMetricsPage
